.Welcome-main-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

#RB-Logo {
  animation: fade-in 3s;
  height: 20em;
}

@keyframes fade-in {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
