.Li0 {
  fill: #1c4cc3;
}
.simpleLoaderMain-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: white;
  display: flex;
}
.loaderSimple-div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
