.App {
  text-align: center;
  width: 100%;
  height: 100%;
}
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  font-family: "Roboto";
  background: #f4f4f4;
}
#root {
  width: 100%;
  height: 100%;
}
